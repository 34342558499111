import api from './api';
import fileDownload from 'js-file-download'

const getAllContracts = (fromDate, toDate, page, status, id, customer, dealerId, vin) => {
    return api
        .get('/contracts', { 
            params: { 
                includeDealer: true, 
                includePricingPositions: true, 
                fromDate, 
                toDate, 
                page,
                status,
                id,
                customer,
                dealerId,
                vin
            } 
        })
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const getContract = (id) => {
    return api
        .get('/contracts/' + id, { params: { includePricingPositions: true } })
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const getContractPDF = (id) => {
    return api
        .get('/contracts/generate/' + id, { responseType: 'blob'})
        .then(response => {
            return fileDownload(response.data, 'contract.pdf')
        })
        .catch(err => {
            throw err
        });
};

const getVINDetails = (code) => {
    return api
        .get('/contracts/vin/' + code)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
}

const getAllContractTemplates = () => {
    return api
        .get('/contract-template')
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
};

const getContractTemplate = (id) => {
    return api
        .get('/contract-template/' + id, { params: { includeProduct: true }})
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
}

const getContractCSV = (fromDate, toDate, id, dealerId, vin, status) => {
    return api
        .get('/contracts/download', {
            params: {
                includeDealer: true,
                includePricingPositions: true,
                fromDate,
                toDate,
                id,
                dealerId,
                vin,
                status
            }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
};


const createContract = (contractPayload) => {
    return api
        .post('/contracts/create', contractPayload)
        .then(response => {
            console.log(response.data)
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const asyncCreateContract = async (contractPayload) => {
    try {
        const res = await api.post('/contracts/create', contractPayload)
        return res
    } catch (e) {
        throw e.response.data
    }
    // return await api
    //     .post('/contracts/create', contractPayload)
    //     .then(response => {
    //         return response.data
    //     })
    //     .catch(err => {
    //         throw err.response.data
    //     });
};


const updateContract = (contractPayload) => {
    return api
        .post('/contracts/update', contractPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const updateContractStatus = (statusPayload) => {
    return api
        .post('/contracts/updateStatus', statusPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};  

const updateContractOwner = (ownerPayload) => {
    return api
        .post('/contracts/updateOwner', ownerPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const updateContractTemplate = (template) => {
    return api
        .post('/contract-template/update', template)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
}

const finalizePendingContracts = () => {
    return api
        .post('/contracts/finalizePending')
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};



const ContractService = {
    getAllContracts,
    getContract,
    getVINDetails,
    getContractPDF,
    getAllContractTemplates,
    getContractTemplate,
    getContractCSV,
    asyncCreateContract,
    createContract,
    updateContract,
    updateContractStatus,
    updateContractOwner,
    updateContractTemplate,
    finalizePendingContracts
};

export default ContractService;