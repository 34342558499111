import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
// actions
import { set_header } from '../../actions/header';
import { 
    set_contract_status, set_contract_vin, set_contract_dealer, set_contract_start_date, set_contract_end_date, 
    set_contract_page, set_contract_id, set_contract_customer, set_contract_product_name, set_contract_product_type,
} 
from '../../actions/contractsFilters';
// components
import ContractPagination from './ContractPagination';
import ContractsTable from './ContractsTable';
import ContractFiltersModal from './ContractFiltersModal';
import Notifications from '../Notifications';
// services
import AuthService from '../../services/AuthService';
import ContractService from '../../services/ContractService';
import UserService from '../../services/UserService';
// other
import moment from 'moment';
import { CSVDownload } from 'react-csv';
// icons
import save from '../../images/save-dark.svg';
import add from '../../images/add.svg';

function ContractsPage({ 
    contractsFilters, set_header, set_contract_status, set_contract_vin, set_contract_dealer, set_contract_start_date, 
    set_contract_end_date, set_contract_id, set_contract_customer, set_contract_product_name, set_contract_product_type,
}) {

    const userRole = UserService.getUserRole();
    const [initialRender, setInitialRender] = useState(true)
    const navigate = useNavigate();
    const [applyResetTable, setApplyResetTable] = useState(false)
    const [pageData, setPageData] = useState({
        rowData: [],
        loading: false, 
        totalPages: 0,
        totalContracts: 0
    })
	const [filters, setFilters] = useState({
        contractTypeFilter: contractsFilters.status,
		vinSearchFilter: contractsFilters.vin,
		dealerSearchFilter: contractsFilters.dealer,
		startDateDay: moment(contractsFilters.startDate),
		endDateDay: moment(contractsFilters.endDate),
        idSearchFilter: contractsFilters.contractId,
        customerSearchFilter: contractsFilters.contractCustomer
	})
    const [currentPage, setCurrentPage] = useState(contractsFilters.page);
    const [filtersModalOpen, setFiltersModalOpen] = useState(false);

    const [csvDownloadReady, setCSVDownloadReady] = useState(false)
    const [csvData, setCSVData] = useState([])

    // set header
    useEffect(() => {
        set_header('Contracts', null)
        setInitialRender(false)
    }, [navigate, set_header]);

    useEffect(() => {
        if (!initialRender) {
            setFilters({
                contractTypeFilter: null,
                vinSearchFilter: '',
                dealerSearchFilter: null,
                startDateDay: moment().subtract(30, 'days'),
                endDateDay: moment(),
                idSearchFilter: '',
                customerSearchFilter: ''
            })
            set_contract_status(null)
            set_contract_vin('')
            set_contract_dealer(null)
            set_contract_start_date(moment().subtract(30, 'days').toISOString(true))
            set_contract_end_date(moment().toISOString(true))
            set_contract_id('')
            set_contract_customer('')
        }
        
    }, [applyResetTable])    

    // handle CSV downloads
    useEffect(() => {
        if (csvData.length) {
            setCSVDownloadReady(true)
        }
    }, [csvData])
    useEffect(() => {
        if (csvDownloadReady) {
            setCSVDownloadReady(false)
        }
    }, [csvDownloadReady])
 

 
    const handleContractTypeChange = (filter) => {
        setFilters(prev => ({
            ...prev,
            contractTypeFilter: filter
        }))
        set_contract_status(filter)
    };    

    const handleFiltersModalOpen = () => {
        setFiltersModalOpen(true)
    };

    const handleFiltersModalClose = () => {
        setFiltersModalOpen(false)
    };

    const handleSubmitFilters = (filters) => {
        setFilters(prev => ({
            ...prev,
            vinSearchFilter: filters.vinSearchFilter,
            dealerSearchFilter: filters.dealerSearchFilter,
            startDateDay: moment(filters.startDateDay),
            endDateDay: moment(filters.endDateDay),
            idSearchFilter: filters.idSearchFilter,
            customerSearchFilter: filters.customerSearchFilter
        }));
        set_contract_vin(filters.vinSearchFilter)
        set_contract_dealer(filters.dealerSearchFilter)
        set_contract_start_date(filters.startDateDay)
        set_contract_end_date(filters.endDateDay)
        set_contract_id(filters.idSearchFilter)
        set_contract_customer(filters.customerSearchFilter)
        setFiltersModalOpen(false)
    };

    const handlePageChange = (page) => {
        setCurrentPage(page)
    };

    const fetchCSVData = async () => {

        setPageData(current => ({
            ...current,
            loading: true
        }))

        try {

            let response = await ContractService.getContractCSV(
                filters.startDateDay, 
                filters.endDateDay, 
                filters.idSearchFilter === '' ? '' : filters.idSearchFilter,
                filters.dealerSearchFilter === null ? null : filters.dealerSearchFilter.id,
                filters.vinSearchFilter === '' ? null : filters.vinSearchFilter,
                filters.contractTypeFilter === '' ? '' : filters.contractTypeFilter
            )

            setCSVData(response.data.map(contract => {

                let managerName = ''
                let managerEmail = ''

                if (contract.vendorInfo !== null) {
                    let manager = contract.vendorInfo.trim().split(',')
                    managerName = manager[0].trim()
                    managerEmail = manager[1].trim()
                }

                const ownerInfo = JSON.parse(contract.owner)

                let columns = {
                    id: contract.id,
                    dealer: contract.dealer.name,
                    product: contract.pricingPositions[0]['title'],
                    term: contract.pricingPositions[0]['term'],
                    activationDate: moment(contract.activationDate).utc().format('YYYY-MM-DD'),
                    vin: contract.vin,
                    userEmail: contract.userEmail,
                    totalPrice: contract.pricingPositions.reduce((sum, price) => sum + parseFloat(price.price.slice(-1)), 0),
                    sellingPrice: contract.sellingPrice,
                    status: contract.status.replace('_', ' '),
                    theftId: contract.theftId,
                    mileage: contract.mileage,
                    vehicleYear: contract.vehicleYear,
                    make: contract.make,
                    model: contract.model,
                    color: contract.color,
                    stock: contract.stock,
                    purchasePrice: contract.purchasePrice,
                    removed: contract.removed ? 'true' : 'false',
                    dealerId: contract.dealerId,
                    userId: contract.userId,
                    createdAt: contract.createdAt,
                    updatedAt: contract.updatedAt,
                    netSuiteDealerId: contract.netSuiteDealerId,
                    legacyContractId: contract.legacyContractId,
                    managerName: managerName,
                    managerEmail: managerEmail,
                    ownerType: ownerInfo.ownerType,
                    firstName: ownerInfo.firstName,
                    lastName: ownerInfo.lastName,
                    email: ownerInfo.email,
                    phone: ownerInfo.phone,
                    address: ownerInfo.address,
                    zip: ownerInfo.zip,
                    city: ownerInfo.city,
                    state: ownerInfo.state,
                    lienholderName: ownerInfo.lineholderName,
                    lienholderAddress: ownerInfo.lineholderAddress
                }

                if (userRole === 'SUPERADMIN') {
                    contract.pricingPositions[0].additionalItems.forEach(item => {
                        columns[item.additionalItemCode.code] = item.fee
                    })
                }

                return columns         
            }))

            setPageData(current => ({
                ...current,
                loading: false
            }))
            
        } catch (e) {

            if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                AuthService.logout()
                navigate('/login')
            }
            Notifications("error", e['message'])

            setPageData(current => ({
                ...current,
                loading: false
            }))
        }
    };

    return (
        <React.Fragment>
            <div className='content'>
                <div className="content-row">
                    <div className='filters'>
                        <div className='filter-roles'>
                            <button 
                                onClick={() => handleContractTypeChange(null)}
                                className={filters.contractTypeFilter === null ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                All
                            </button>
                            <button 
                                onClick={() => handleContractTypeChange('PENDING')}
                                className={filters.contractTypeFilter === 'PENDING' ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                Pending
                            </button>
                            <button 
                                onClick={() => handleContractTypeChange('NON_FINALIZED')}
                                className={filters.contractTypeFilter === 'NON_FINALIZED' ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                Non Finalized
                            </button>

                            <button 
                                onClick={() => handleContractTypeChange('MANUAL_FINALIZED')}
                                className={filters.contractTypeFilter === 'MANUAL_FINALIZED' ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                Manual Finalized
                            </button>
                            <button 
                                onClick={() => handleContractTypeChange('AUTO_FINALIZED')}
                                className={filters.contractTypeFilter === 'AUTO_FINALIZED' ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                Auto Finalized
                            </button>
                            <button 
                                onClick={() => handleContractTypeChange('CANCELLED')}
                                className={filters.contractTypeFilter === 'CANCELLED' ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                Cancelled
                            </button>
                            <button 
                                onClick={() => handleContractTypeChange('TRANSFERRED')}
                                className={filters.contractTypeFilter === 'TRANSFERRED' ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                Transferred
                            </button>
                        </div>
                        <div className='filter-actions'>
                            <button
                                className='link__form-add'
                                onClick={fetchCSVData}
                            >
                                <span className="hide-for-mobile">
                                    Download
                                </span>
                                <img src={save} alt="Save"/>
                            </button>

                            {csvDownloadReady && (
                                <CSVDownload
                                    data={csvData}
                                    filename={'contracts.csv'}
                                />
                            )}
                            
                            <Link 
                                to='/addcontract'
                                className='link__form-add'
                            > 
                                <span className="hide-for-mobile">
                                    Add
                                </span>
                                <img src={add} alt="Add"/>
                            </Link>
                        </div>
                    </div>
                </div>


 
                <div className="content-row">
                    <div className='contracts-table__header'>
                        <div className="contract-table__header-group">
                            <div>
                                <button
                                    onClick={() => handleFiltersModalOpen()}
                                    className='header-reset'
                                >
                                    Filters
                                </button>
                                
                            </div>
                            <button
                                onClick={() => setApplyResetTable(prev => !prev)}
                                className='filter-buttons active'
                            >
                                Reset Filters
                            </button>
                            <div className='header-filters'>
                                {
                                    filters.idSearchFilter && filters.idSearchFilter !== '' &&
                                        <div className="vertical-align-div">
                                            ID: {filters.idSearchFilter}
                                        </div>
                                }
                                {
                                    filters.vinSearchFilter && filters.vinSearchFilter !== '' &&
                                        <div className="vertical-align-div">
                                            VIN: {filters.vinSearchFilter}
                                        </div>
                                }
                                {
                                    filters.customerSearchFilter && filters.customerSearchFilter !== '' &&
                                        <div className="vertical-align-div">
                                            Customer: {filters.customerSearchFilter}
                                        </div>
                                }
                                {
                                    filters.dealerSearchFilter && filters.vinSearchFilter === '' &&
                                        <div className="vertical-align-div">
                                            Dealer: {filters.dealerSearchFilter.name}
                                        </div>
                                }
                                {
                                    <div className="vertical-align-div">
                                        {moment(filters.startDateDay).format('MMMM D')} - {moment(filters.endDateDay).format('MMMM D')}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <ContractsTable
                    currentPage={currentPage}
                    handlePageData={setPageData}
                    applyResetTable={applyResetTable}
                    filters={filters}
                />
                <ContractPagination
                    totalPages={pageData.totalPages}
                    handlePageChange={handlePageChange}
                    loading={pageData.loading}
                    applyResetTable={applyResetTable}
                    filters={filters}
                    
                />
            </div>

			<ContractFiltersModal
				filtersModalOpen={filtersModalOpen}
				handleFiltersModalClose={handleFiltersModalClose}
                handleSubmitFilters={handleSubmitFilters}
				filters={filters}
				applyResetTable={applyResetTable}
			/>
            
            
        </React.Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        contractsFilters: state.contractsFilters
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub)),
        set_contract_status: (status) => dispatch(set_contract_status(status)),
        set_contract_vin: (vin) => dispatch(set_contract_vin(vin)),
        set_contract_dealer: (dealer) => dispatch(set_contract_dealer(dealer)),
        set_contract_start_date: (startDate) => dispatch(set_contract_start_date(startDate)),
        set_contract_end_date: (endDate) => dispatch(set_contract_end_date(endDate)),
        set_contract_page: (page) => dispatch(set_contract_page(page)),
        set_contract_id: (contractId) => dispatch(set_contract_id(contractId)),
        set_contract_customer: (contractCustomer) => dispatch(set_contract_customer(contractCustomer)),
        set_contract_product_name: (contractProductName) => dispatch(set_contract_product_name(contractProductName)),
        set_contract_product_type: (contractProductType) => dispatch(set_contract_product_type(contractProductType))

   };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractsPage);