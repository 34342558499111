import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Row,
    Col,
    Button,
    ButtonGroup,
    Input
} from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker'
import { PatternFormat } from 'react-number-format';
import { States } from 'countries-states-cities-service';
import Numeral from 'numeral';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { 
    useTable, 
    usePagination, 
    useFilters,
    useExpanded,
    useRowSelect,
    useSortBy 
} from 'react-table';

// table filters
import { Filter } from '../TableFilters';

// services
import TokenService from '../../services/TokenService';
import AuthService from '../../services/AuthService';
import DealerService from '../../services/DealerService';
import ContractService from '../../services/ContractService';
import ProductService from '../../services/ProductService';
import UserService from '../../services/UserService';

// components
import Notifications from '../Notifications';
import LoadingPage from '../LoadingPage';

// images
import checkboxEmpty from '../../images/checkbox-empty.svg';
import checkboxFull from '../../images/checkbox-full.svg';
import search from '../../images/search.svg';
import sort from '../../images/sort.svg';
import ascending from '../../images/ascending.svg';
import descending from '../../images/descending.svg';
import print from '../../images/print.svg';

function ContractForm(props) {
    const navigate = useNavigate();

    const [successful, setSuccessful] = useState(false)
    const [allDealers, setAllDealers] = useState([]);
    const [allDealersLabel, setAllDealersLabel] = useState([])
    const [dealer, setDealer] = useState(null);
    const [dealerLabel, setDealerLabel] = useState(null);
    const [activationDate, setActivationDate] = useState(new Date());
    const [vin, setVin] = useState('');
    const [theftID, setTheftID] = useState('');
    const [mileage, setMileage] = useState('');
    const [vehicleCondition, setVehicleCondition] = useState(null);
    const [year, setYear] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [color, setColor] = useState('');
    const [stock, setStock] = useState('');
    const [ownerType, setOwnerType] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState(null);
    const [stateLabels, setStateLabels] = useState([])
    const [lineholderName, setLineholderName] = useState('');
    const [lineholderAddress, setLineholderAddress] = useState('');
    const [vehPurchasePrice, setVehPurchasePrice] = useState('');
    const [validateVINLoading, setValidateVINLoading] = useState(false);

    const [selectedPricingPosition, setSelectedPricingPosition] = useState([]);
    const [allPricingPositions, setAllPricingPositions] = useState([]);

    const userRole = UserService.getUserRole();
    const user_id = jwt_decode(TokenService.getLocalAccessToken()).userId;

    const [readOnly, setReadOnly] = useState(false);
    const [canEditOwnerInfo, setCanEditOwnerInfo] = useState(true)

    useEffect(() => {
        setAllPricingPositions(prev => (
            prev.map(pp => {
                return {
                    ...pp,
                    created: checkUserActions(pp.id)
                }
            })
        ))
    }, [props.createdContracts] )

    useEffect(() => {
        setStateLabels(States.getStates({ filters: { country_code: 'US' } }).map((state) => {
            return {
                value: state.state_code,
                label: state.name
            }
        }))

        DealerService.getAllDealers().then(response => {
            setAllDealers(response.data)
            setAllDealersLabel(response.data.map((dealer) => {
                return {
                    value: dealer.name,
                    label: dealer.name
                }
            }).sort((a, b) => a['label'].localeCompare(b['label'])))
            setSuccessful(true)
        }).catch(err => {
            if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                AuthService.logout()
                navigate('/login')
            }
            Notifications("error", err['message'])
            setSuccessful(false)
        })

        if (props.contract) {
            
            setReadOnly(true) 

            if (userRole === 'METROTECH_MANAGER' || userRole === 'METROTECH_TERRITORY_MANAGER' || userRole === 'DEALERSHIP_FI') {
                setCanEditOwnerInfo(false)
            }
            if (props.contract.status === 'CANCELLED') {
                setCanEditOwnerInfo(false)
            }

            const owner = JSON.parse(props.contract.owner)

            setDealerLabel({
                value: props.contract.dealer.name,
                label: props.contract.dealer.name
            })
            setDealer(props.contract.dealer)
            setVin(props.contract.vin ? props.contract.vin : '')
            setTheftID(props.contract.theftId ? props.contract.theftId : '')
            setMileage(props.contract.mileage ? props.contract.mileage : '')
            setVehicleCondition(props.contract.isNewCondition)
            setYear(props.contract.vehicleYear ? props.contract.vehicleYear : '')
            setMake(props.contract.make ? props.contract.make : '')
            setModel(props.contract.model ? props.contract.model : '')
            setColor(props.contract.color ? props.contract.color : '')
            setStock(props.contract.stock ? props.contract.stock : '')
            setOwnerType(owner.ownerType)
            setFirstName(owner.firstName ? owner.firstName : '')
            setLastName(owner.lastName ? owner.lastName : '')
            setPhone(owner.phone ? owner.phone : '')
            setEmail(owner.email ? owner.email : '')
            setAddress(owner.address ? owner.address : '')
            setZip(owner.zip ? owner.zip : '')
            setCity(owner.city ? owner.city : '')
            setLineholderName(owner.lienholderName ? owner.lienholderName : '')
            setLineholderAddress(owner.lienholderAddress ? owner.lienholderAddress : '')
            setVehPurchasePrice(props.contract.purchasePrice ? props.contract.purchasePrice : '')
 
            setSelectedPricingPosition([
                {

                    title: props.contract.pricingPositions[0].title,
                    bvProductCode: props.contract.pricingPositions[0].bvcode,
                    contract: props.contract.pricingPositions[0].contractLetter,
                    term: props.contract.pricingPositions[0].term,
                    price: props.contract.pricingPositions[0].price,
                    sellingPrice: props.contract.sellingPrice
                }
            ])

            const selectedState = States.getStates({
                filters: {
                    country_code: 'US',
                    state_code: owner.state
                }
            })[0]

            if (selectedState) {
                setState({
                    value: owner.state,
                    label: selectedState.name
                })
            }
        }
    }, [navigate, props.contract])

    useEffect(() => {
        if (dealer) {
            getProducts(dealer.id)
        }
    }, [vehicleCondition]);


    const getPDFLink = (id) => {
        Notifications('info', 'Download has started...')

        ContractService.getContractPDF(id)
            .then(response => {
                Notifications('success', 'Contract downloaded.')
            }, e => {
                console.log(e)
                Notifications('error', 'Unable to download contract.')
            })
    };

    const getProducts = (dealerId) => {
        ProductService.getProductsFromDealer(dealerId)
            .then(response => {
                let prices = []

                response.data.forEach((product) => {
                    product.pricingPosition.forEach((price) => {
                        // get all new vehicles
                        let vehicleConditionCode;

                        if (vehicleCondition) vehicleConditionCode = '0';
                        else vehicleConditionCode = '1';

                        if (price.dealerId === dealerId && (product.condition.length === 2 || 
                            product.condition[0] === vehicleConditionCode)) {
                            prices.push({
                                productId: product.id,
                                ...price
                            })
                        }
                    })
                })
                setAllPricingPositions(prices.map(p => (
                    {
                        id: p.id,
                        productId: p.productId,
                        title: p.title,
                        bvProductCode: p.bvcode,
                        contract: p.contractLetter,
                        term: p.term,
                        price: p.price,
                        sellingPrice: '',
                        selected: false,
                        print: '',
                        created: checkUserActions(p.id)
                    }
                )))
            }).catch(err => {
                if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", err['message'])
            })
    }

    const checkUserActions = (ppId) => {
        if (props.createdContracts) {
            const printableContract = props.createdContracts.find(contract => contract.pricingPositionId[0] === ppId)
            return printableContract
        } else {
            return null
        }
        
    }

    const handleDealerChange = (dealerLabel) => {
        setDealerLabel(dealerLabel)
        const selectedDealer = allDealers.find((dealer) => dealer.name === dealerLabel.value)
        setDealer(selectedDealer)
        getProducts(selectedDealer.id)
    };

    const handleStateChange = (stateLabel) => {
        setState(stateLabel)
    }

    const validateVIN = (code) => {
        setValidateVINLoading(true)
        ContractService.getVINDetails(code)
            .then(response => {
                setValidateVINLoading(false)

                if (response.data === null) {
                    Notifications("error", "Please Enter a VIN.")
                } else if (!["0", "3", "4"].includes(response.data.Results[0].ErrorCode.split(",")[0])) {
                    Notifications("error", "Invalid VIN.")
                } else {
                    setYear(response.data.Results[0].ModelYear)
                    setMake(response.data.Results[0].Make)
                    setModel(response.data.Results[0].Model)
                }
            }).catch(err => {
                if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                } else if (err.statusCode === 400) {
                    Notifications("vinInfo", "Please manually enter the vehicle year.")
                } else {
                    Notifications("error", err['message'])
                }
            })
    };

    const onSubmit = (e) => {

        e.preventDefault();

        if (dealer === "" || dealer === undefined || dealer === null) {
            Notifications("error", "Dealer is required.")
        }
        if (vin === "" || vin === undefined) {
            Notifications("error", "VIN is required.")
        }
        if (vehicleCondition === "" || vehicleCondition === null) {
            Notifications("error", "Vehicle condition is required.")
        }
        if (year === "" || year === null) {
            Notifications("error", "Vehicle year is required.")
        }
        if (ownerType === "" || ownerType === null) {
            Notifications("error", "Owner type is required.")
        }
        if (firstName === "" || firstName === null) {
            Notifications("error", "Owner first name is required.")
        }
        if (lastName === "" || lastName === null) {
            Notifications("error", "Owner last name is required.")
        }
        if (vehPurchasePrice === "" || vehPurchasePrice === undefined || isNaN(vehPurchasePrice)) {
            Notifications("error", "Vehicle purchase price is required.")
        }

        // if (readOnly !== 'readonly' && (selectedPricingPositionId === '' || selectedPricingPositionId === null)) {
        //     Notifications("error", "Product is required.")
        // }


        const selectedProductItems = allPricingPositions.filter(item => {
            return item.selected
        })

        if (!readOnly && selectedProductItems.length === 0) {
            Notifications('error', 'Product is required')
        }

        selectedProductItems.forEach(item => {
            if (item.sellingPrice === '' || item.sellingPrice === null) {
                Notifications('error', 'Selling price is required.')
            }
        })

        const contract = {
            vin: vin,
            dealerId: dealer ? dealer.id : null,
            userId: user_id,
            theftId: theftID,
            mileage: parseInt(mileage),
            isNewCondition: vehicleCondition,
            vehicleYear: year,
            make: make,
            model: model,
            color: color,
            stock: stock,
            purchasePrice: parseFloat(vehPurchasePrice),
            activationDate: activationDate.toISOString(),
            owner: JSON.stringify({
                ownerType: ownerType,
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                email: email,
                address: address,
                zip: zip,
                city: city,
                state: state ? state.value : null,
                lienholderName: lineholderName,
                lienholderAddress: lineholderAddress,
            }),
            products: selectedProductItems
        };

        if (dealer != null && !dealer.validateVIN) {
            props.onSubmit(false, contract, null)
        } else {
            ContractService.getVINDetails(vin)
                .then(response => {

                    let vinRequired = true
                    setValidateVINLoading(false)

                    if (response.data === null || (!["0", "3", "4"].includes(response.data.Results[0].ErrorCode.split(",")[0]))) {
                        Notifications("error", "VIN is invalid.")
                    } else if (response.data.Results[0].ModelYear !== year && vinRequired) {
                        Notifications("error", "Vehicle year does not match with the vehicle year of the VIN.")
                    } else {
                        setYear(response.data.Results[0].ModelYear)
                        setMake(response.data.Results[0].Make)
                        setModel(response.data.Results[0].Model)
                    }

                    props.onSubmit(vinRequired, contract, response.data);

                }).catch(err => {
                    if (err.statusCode === 400) {
                        let vinRequired = false

                        props.onSubmit(vinRequired, contract, null);
                    } else {
                        Notifications("error", err['message'])
                    }
                })   
        }
            
        
    };

    const onVehicleConditionClick = (vehicleCondition) => {
        setVehicleCondition(vehicleCondition)
    }

    const singleRowColumns = React.useMemo(
        () => [
            {
                Header: 'Product Name',
                accessor: 'title',
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Accounting Product Code',
                accessor: 'bvProductCode',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Contract',
                accessor: 'contract',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Term',
                accessor: 'term',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    let tempValue = value;

                    if (value === 'life') {
                        tempValue = 'Life'
                    } else {
                        tempValue = `${value} year${parseInt(value) > 1 ? 's' : ''}`
                    }
        
                    return (
                        <div className="table-cell__div">
                            {tempValue}
                        </div>
                    );
                }
            },
            {
                Header: 'Cost',
                accessor: 'price',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    if (value.length === 3){
                        let total = value[2];

                        return (
                            <div className="table-cell__div">
                                <div className="badge badge__terms">
                                    {Numeral(total).format("$0,0.00")}
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div className="table-cell__div">
                                <span>Not Set</span>
                            </div>
                        );
                    }
                }
            },
            {
                Header: 'Selling Price',
                accessor: 'sellingPrice',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            <div className='badge badge__terms'>
                                {Numeral(value).format("$0,0.00")}
                            </div>
                        </div>
                    );
                }
            },
        ], []
    )
    


    const columns = React.useMemo(
        () => [
            {
                Header: 'Select',
                accessor: 'selected',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ row, cell }) => {
                    return (
                        <div className="table-cell__div flex-center" onClick={() => onSelectRow(row)}>
                            {
                                cell.value ?
                                    <img src={checkboxFull} alt="Checkbox Full"/>
                                    :
                                    <img src={checkboxEmpty} alt="Checkbox Empty"/>
                            }
                        </div>
                    );
                }
            },
            {
                Header: 'Product Name',
                accessor: 'title',
                disableFilters: true,
                // Filter: InputColumnFilter,
                // ref: titleFilterRef,
                // filter: 'equals',
                // id: 'title',
                // name: 'title',
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Accounting Product Code',
                accessor: 'bvProductCode',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Contract',
                accessor: 'contract',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Term',
                accessor: 'term',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    let tempValue = value;

                    if (value === 'life') {
                        tempValue = 'Life'
                    } else {
                        tempValue = `${value} year${parseInt(value) > 1 ? 's' : ''}`
                    }
        
                    return (
                        <div className="table-cell__div">
                            {tempValue}
                        </div>
                    );
                }
            },
            {
                Header: 'Cost',
                accessor: 'price',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    if (value.length === 3){
                        let total = value[2];

                        return (
                            <div className="table-cell__div">
                                <div className="badge badge__terms">
                                    {Numeral(total).format("$0,0.00")}
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div className="table-cell__div">
                                <span>Not Set</span>
                            </div>
                        );
                    }
                }
            },
            {
                Header: 'Selling Price',
                accessor: 'sellingPrice',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ row, cell }) => {
                    const [value, setValue] = useState(cell.value);

                    const onChange = (e) => {
                        setValue(e.target.value)
                    }

                    const onBlur = () => {
                        updateData(row.original.id, 'sellingPrice', value)
                    }

                    return (
                        <div className="table-cell__div">
                            <Input 
                                type='number'
                                placeholder=''
                                value={value}
                                className='input input__form'
                                onChange={onChange}
                                onBlur={onBlur}
                                disabled={row.original.selected ? false : true}
                            />
                        </div>
                    );
                }
            },
            {
                Header: 'Print',
                accessor: 'created',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {
                                value === undefined || value.status === 'PENDING'
                                ? (
                                    <div className="contracts-action__div">
                                        
                                    </div>
                                )
                                :  (
                                    <div className="contracts-action__div">
                                        <button 
                                            onClick={() => {getPDFLink(value.contractId)}}
                                            className='button-no-background'
                                        >
                                            <div>
                                                <img src={print} alt="Print"/>
                                            </div>
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    );
                }
            }
        ], []
    )
    
    const onSelectRow = (row) => {
        setAllPricingPositions(prev => (
            prev.map(d => {
                return {
                    ...d,
                    selected: d['id'] === row.original.id ? !d['selected'] : d['selected']
                }
            })
        ))
    }

    const updateData = (id, prop, value) => {
        setAllPricingPositions(prev => (
            prev.map(d => {
                return {
                    ...d,
                    [prop]: d['id'] === id ? value : d[prop]
                }
            })
        ))
    }
    
    function Table({ columns, data, updateData }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page
        } = useTable(
            {
                columns,
                data,
                initialState: {  
                    pageIndex: 0, 
                    pageSize: 1000, 
                    hiddenColumns: [],
                    sortBy: [
                        {   
                            id: 'title',
                            desc: false
                        }
                    ]
                },
                sortTypes: {
                    alphanumeric: (row1, row2, columnName) => {
                        const rowOneColumn = row1.values[columnName];
                        const rowTwoColumn = row2.values[columnName];
                        if (isNaN(rowOneColumn)) {
                            return rowOneColumn.toUpperCase() >
                                rowTwoColumn.toUpperCase()
                                ? 1
                                : -1;
                        }
                        return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
                    },
                },
                updateData
            },
            useFilters,
            useSortBy,
            useExpanded,
            usePagination,
            useRowSelect,
        )
        
        const generateSortingIndicator = (column) => {
            if (column.canSort) {
                return column.isSorted ? 
                    (
                        column.isSortedDesc ? 
                            <img {...column.getSortByToggleProps()} src={descending} alt="Descending"/> 
                            : 
                            <img {...column.getSortByToggleProps()} src={ascending} alt="Ascending"/>
                    ) 
                    : 
                    <img {...column.getSortByToggleProps()} src={sort} alt="Sort"/>;
            } else {
                return null
            }
        };
    
        const generateSearchIndicator = (column) => {
            if (column.canFilter) {
                return <img src={search} alt="Search"/>
            } else {
                return null
            }
        }
    
        const generateVerticalLine = (column) => {
            if (column.canFilter && column.canSort) {
                return <div className="table-header__vertical-line"></div>
            } else {
                return null
            }
        }

      
        // Render the UI for your table
        return (
            <React.Fragment>
                <div className="table-div">
                    <table {...getTableProps()}>
                        <thead>
                            {
                                headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map(column => (
                                                <th className={column.canFilter ? 'table-header filterable' : 'table-header'} {...column.getHeaderProps()}>
                                                    <div className="table-header__div">
                                                        {column.render('Header')}
                                                        <div className="table-header__icons">
                                                            {generateSearchIndicator(column)}
                                                            {generateVerticalLine(column)}
                                                            {generateSortingIndicator(column)}
                                                        </div>
                                                    </div>
                                                    {
                                                        column.canFilter && <Filter column={column} />
                                                    }
                                                </th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()} className="table-row">
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>{cell.render ('Cell')}</td>
                                            })}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
          </React.Fragment>
        )
    }

    if (!successful) {
        return (
            <React.Fragment>
                <LoadingPage />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className='details-page__form'>
                <div>
                    <h2>Contract Information</h2>
                </div>
                <form id='contract-form' onSubmit={onSubmit}>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Dealer*
                                    </div>
                                    <Select 
                                        options={allDealersLabel}
                                        value={dealerLabel}
                                        onChange={handleDealerChange}
                                        id='selectDealers'
                                        isDisabled={readOnly}
                                    />
                                
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Activation Date*
                                    </div>
                                </label>
                                {
                                    readOnly ?
                                        <input
                                            type='text'
                                            value={moment(props.contract.activationDate).format('MMM DD, YYYY')}
                                            className='input input__form'
                                            disabled={readOnly}
                                        />
                                        :
                                        <DatePicker 
                                            selected={activationDate}
                                            onChange={setActivationDate}
                                            minDate={new Date()}
                                            className="activation-date-picker"
                                            calendarClassName="activation-date-calendar"
                                            dateFormat="MMM dd, yyyy"
                                            fixedHeight
                                            onFocus={e => e.target.blur()}
                                        />
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        VIN*
                                    </div>
                                    <div style={{ display: 'flex', gap: '1rem' }}>
                                        <input 
                                            type='text'
                                            placeholder='Enter VIN'
                                            value={vin}
                                            onChange={(e) => setVin(e.target.value)}
                                            className='input input__form'
                                            disabled={readOnly}
                                        />
                                        <Button
                                            onClick={() => validateVIN(vin)}
                                            className="form-button"
                                            style={{ whiteSpace: 'nowrap' }}
                                            disabled={readOnly || validateVINLoading}
                                        >
                                            Validate VIN
                                        </Button>
                                    </div>
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Vehicle Condition*
                                    </div>
                                    <ButtonGroup>
                                        <Button 
                                            onClick={() => onVehicleConditionClick(true)}
                                            className="form-button"
                                            active={vehicleCondition===true}
                                            disabled={readOnly}
                                        >
                                            New
                                        </Button>
                                        <Button
                                            onClick={() => onVehicleConditionClick(false)}
                                            className="form-button"
                                            active={vehicleCondition===false}
                                            disabled={readOnly}
                                        >
                                            Used
                                        </Button>
                                    </ButtonGroup>
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Theft ID
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Theft ID'
                                        value={theftID}
                                        onChange={(e) => setTheftID(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Mileage
                                    </div>
                                    <Input 
                                        type='number'
                                        placeholder='Enter Mileage'
                                        value={mileage}
                                        onChange={(e) => setMileage(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Year*
                                    </div>
                                    <Input 
                                        type='number'
                                        placeholder='Enter Year'
                                        value={year}
                                        onChange={(e) => setYear(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Make
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Make'
                                        value={make}
                                        onChange={(e) => setMake(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Model
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Model'
                                        value={model}
                                        onChange={(e) => setModel(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} lg={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Color
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Color'
                                        value={color}
                                        onChange={(e) => setColor(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Stock
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Stock'
                                        value={stock}
                                        onChange={(e) => setStock(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={3}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Owner Type*
                                    </div>
                                    <ButtonGroup>
                                        <Button 
                                            onClick={() => setOwnerType('Individual')}
                                            className="form-button"
                                            active={ownerType === 'Individual'}
                                            disabled={!canEditOwnerInfo}
                                        >
                                            Individual
                                        </Button>
                                        <Button
                                            onClick={() => setOwnerType('Business')}
                                            className="form-button"
                                            active={ownerType === 'Business'}
                                            disabled={!canEditOwnerInfo}
                                        >
                                            Business
                                        </Button>
                                    </ButtonGroup>
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        First Name*
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter First Name'
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        className='input input__form'
                                        disabled={!canEditOwnerInfo}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Last Name*
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Last Name'
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        className='input input__form'
                                        disabled={!canEditOwnerInfo}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Phone
                                    </div>
                                    <PatternFormat
                                        type='tel'
                                        format='(###) ###-####'
                                        mask='_'
                                        value={phone}
                                        onValueChange={value => setPhone(value.formattedValue)}
                                        className='input input__form'
                                        disabled={!canEditOwnerInfo}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Email
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className='input input__form'
                                        disabled={!canEditOwnerInfo}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Address
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Address'
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        className='input input__form'
                                        disabled={!canEditOwnerInfo}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        ZIP
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter ZIP'
                                        value={zip}
                                        onChange={(e) => setZip(e.target.value)}
                                        className='input input__form'
                                        disabled={!canEditOwnerInfo}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        City
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter City'
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        className='input input__form'
                                        disabled={!canEditOwnerInfo}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        State
                                    </div>
                                    <Select 
                                        options={stateLabels}
                                        value={state}
                                        onChange={handleStateChange}
                                        id='selectStates'
                                        isDisabled={!canEditOwnerInfo}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Lienholder Name
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Lienholder Name'
                                        value={lineholderName}
                                        onChange={(e) => setLineholderName(e.target.value)}
                                        className='input input__form'
                                        disabled={!canEditOwnerInfo}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Lienholder Address
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Lienholder Address'
                                        value={lineholderAddress}
                                        onChange={(e) => setLineholderAddress(e.target.value)}
                                        className='input input__form'
                                        disabled={!canEditOwnerInfo}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Vehicle Purchase Price*
                                    </div>
                                    <Input 
                                        type='number'
                                        placeholder='Enter Vehicle Purchase Price'
                                        value={vehPurchasePrice}
                                        onChange={(e) => setVehPurchasePrice(e.target.value)}
                                        className='input input__form'
                                        disabled={!canEditOwnerInfo}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                </form>
                <hr />

                {
                    readOnly ?
                        <div>
                            <h2>Selected Product</h2>
                        </div>
                        :
                        <div>
                            <h2>Products*</h2>
                        </div>
                }

                {
                    (dealer === null && !readOnly) &&
                        <div style={{ color: 'red' }}>
                            <span>
                                Please select a dealer.
                            </span>
                        </div>
                }
                {
                    (vehicleCondition === null && !readOnly) &&
                        <div style={{ color: 'red' }}>
                            <span>
                                Please select vehicle condition.
                            </span>
                        </div>
                }
                {
                    (dealer !== null && vehicleCondition !== null && !readOnly) &&
                        <div className="table overflow contract-products">
                            <Table columns={columns} data={allPricingPositions} updateData={updateData} />
                        </div>
                }
                {
                    readOnly &&
                    <div className="table overflow contract-products">
                        <Table columns={singleRowColumns} data={selectedPricingPosition} />
                    </div>
                }
            </div> 
        </React.Fragment>
    );
};

export default ContractForm;